var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timetable"},[_c('h1',{staticClass:"header-title"},[_vm._v("Расписание групп")]),_c('yandex-map',{ref:"map",staticClass:"ymap-main",attrs:{"ymap-class":"ymap-body","settings":_vm.settings,"coords":_vm.phoenix.coordsPhoenix,"zoom":"12","controls":[]}},[_c('ymap-marker',{attrs:{"coords":_vm.phoenix.coordsPhoenix,"options":{
        balloonCloseButton: true,
        balloonMinWidth: 340,
        hideIconOnBalloonOpen: false,
        balloonOffset: [0, 0],
      },"marker-id":"group","icon":_vm.phoenix.markerPhoenix}},[_c('BaloonCustom',{attrs:{"slot":"balloon","times":_vm.phoenix.timePhoenix},slot:"balloon"})],1),_c('ymap-marker',{attrs:{"coords":_vm.ligthHouse.coordlight,"marker-id":"group","icon":_vm.ligthHouse.markerlight,"options":{
        balloonCloseButton: true,
        balloonMinWidth: 340,
        hideIconOnBalloonOpen: false,
        balloonOffset: [0, 0],
      }}},[_c('BaloonCustom',{attrs:{"slot":"balloon","times":_vm.ligthHouse.timeLightHouse},slot:"balloon"})],1),_c('ymap-marker',{attrs:{"coords":_vm.desna.coord,"marker-id":"group","icon":_vm.desna.marker,"options":{
        balloonCloseButton: true,
        balloonMinWidth: 340,
        hideIconOnBalloonOpen: false,
        balloonOffset: [0, 0],
      }}},[_c('BaloonCustom',{attrs:{"slot":"balloon","times":_vm.desna.time},slot:"balloon"})],1)],1),_c('div',{staticClass:"group-body"},[_c('img',{staticClass:"logo-group",attrs:{"src":require("../assets/img/logo-phoenix.png"),"alt":"Собрания группы \"Феникс\""}}),_c('TimeTemplate',{attrs:{"data":_vm.phoenix.timePhoenix,"title":_vm.phoenix.title}}),_c('div',{staticClass:"group-body__address"},[_c('find-template',{attrs:{"address":_vm.phoenix.address,"images":_vm.phoenix.imgList,"coords":_vm.phoenix.coordsPhoenix,"marker":_vm.phoenix.markerPhoenix}})],1)],1),_c('div',{staticClass:"group-body"},[_c('img',{staticClass:"logo-group",attrs:{"src":require("../assets/img/lith-house.png"),"alt":"Собрания группы \"Маяк\""}}),_c('TimeTemplate',{attrs:{"data":_vm.ligthHouse.timeLightHouse,"title":_vm.ligthHouse.title}}),_c('div',{staticClass:"group-body__address"},[_c('find-template',{attrs:{"address":_vm.ligthHouse.address,"images":_vm.ligthHouse.imgList,"coords":_vm.ligthHouse.coordlight,"marker":_vm.ligthHouse.markerlight}})],1)],1),_c('div',{staticClass:"group-body"},[_c('img',{staticClass:"logo-group",attrs:{"src":require("../assets/img/desna/logo.jpg"),"alt":_vm.desna.title}}),_c('TimeTemplate',{attrs:{"data":_vm.desna.time,"title":_vm.desna.title}}),_c('div',{staticClass:"group-body__address"},[_c('find-template',{attrs:{"address":_vm.desna.address,"images":_vm.desna.imgList,"coords":_vm.desna.coord,"marker":_vm.desna.marker,"link":_vm.desna.linkTg}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }