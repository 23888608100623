<template>
  <div class="how-zoom">
    <button class="btn" @click="openModal">как попасть на онлайн группу</button>
    <modal
      name="zoom"
      :width="700"
      :height="750"
      :adaptive="true"
      :scrollable="true"
    >
      <modal-Info />
    </modal>
  </div>
</template>

<script>
import ModalInfo from "./ModalInfoZoom";
export default {
  components: {
    ModalInfo,
  },
  methods: {
    openModal() {
      this.$modal.show("zoom");
    },
  },
  mounted() {
    console.log("ios", this.ios);
    console.log("andr", this.andr);
  },
};
</script>

<style lang="scss">
.how-body {
  margin: 2rem 0;
}
.zoom-help__body {
  margin: 2rem;
  overflow-y: auto;
}
.zoom-andorid,
.zoom-ios {
  &__body {
    display: flex;
    flex-direction: column;
  }
}
.vm--modal {
  overflow-y: auto;
}
@media screen and (max-width: 600px) {
  .vm--modal {
    width: 90% !important;
    margin: auto;
  }
  .link-tabs {
    a {
      font-size: 1rem;
    }
    .tabs-component-tabs {
      margin: 12px;
      padding: 0;
    }
  }
  .zoom-help__body {
    margin: 1rem;
  }
}
</style>