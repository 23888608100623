<template>
  <div class="time-body">
    <h2 class="group-body__title">{{ title }}</h2>
    <div class="time-row" v-for="(item, index) of data" :key="index">
      <div class="time-row__week">
        {{ item.week }}<br />
        <span v-if="item.isOpen">
          <div class="time-row__icon" :data-icon-title="item.isOpen">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="24px" height="24px">
              <path fill="#2196f3" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/>
              <path fill="#fff" d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"/>
            </svg>
          </div>
        </span>
      </div>
      <div class="time-row__time">
        <div class="time__text">
          <span class="time__clock">c {{ item.timeStart }}</span>
        </div>
        <div class="time__text">
          <span class="time__clock">до {{ item.timeEnd }}</span>
        </div>
      </div>
      <div v-if="item.pause" class="time-row__break">
        <span class="time__text">
          <span class="time__text-first">перерыв</span>
        </span>
        <span class="time__text"><i class="far fa-clock"></i> 10 минут</span>
      </div>
      <div v-else class="time-row__break">без перерыва</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
    title: String,
  },
};
</script>