<template>
  <div class="address-light">
    <button class="btn btn_address" @click="toggle">
      <p class="text-block">
        Cобрание проходит по адерсу:
        <strong>{{ address }}</strong>
      </p>
    </button>
    <div class="address-light__map" v-show="visible">
      <button class="btn" @click="openModal" v-if="images">Расположение на фото</button>
      <modal name="details" :width="600" :height="500" :adaptive="true">
        <slider :autoplay="false" height="500px">
          <slider-item v-for="(i, index) in images" :key="index" :style="i">
            <div class="count">{{ index + 1 }}</div>
            <div class="social" v-if="link && index === 3">
              <a class="social__link" :href="link" target="_blank">Написать в телеграмм</a>
            </div>
          </slider-item>
        </slider>
      </modal>
      <yandex-map
        ref="map"
        class="ymap"
        ymap-class="ymap-body"
        :settings="settings"
        :coords="coords"
        zoom="17"
        :controls="[]"
      >
        <ymap-marker
          :coords="coords"
          marker-id="group"
          :icon="marker"
        />
      </yandex-map>
    </div>
  </div>
</template>

<script>
import toggleMixin from "@/toggleMixin";

import { yandexMap, ymapMarker, loadYmap } from "vue-yandex-maps";

export default {
  mixins: [toggleMixin],
  data(){
    return{
      settings: {
        apiKey: '4566896d-289c-4258-a09c-019cb859197e',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },
      map: false,
      slider: false,
    }
  },
  props: {
     address: {
          type: String,
          default: 'Введите адресс'
     },
     images: Array,
     coords: Array,
     marker: Object,
     link: String,
  },
  methods: {
    // toggle() {
    //   this.map = !this.map
    // },
    openModal(){
      // this.slider = !this.slider
      this.$modal.show('details')
    }
  },
  components: {
    yandexMap,
    ymapMarker,
  },
  async mounted() {
    let yamp = null;
    await loadYmap(this.settings);
  },
};
</script>

<style lang="scss">
.ymap{
  &-body{
    width: 100%;
    height: 450px;
  }
}

.slider-item{
  display: flex;
  flex-flow: column;
  gap: 20px;
}
</style>