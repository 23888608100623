<template>
  <div class="balloon">
    <!-- <div class="ballon__body">
      <div class="balloon__block" v-for="(time, index) in times" :key="index">
        <div class="balloon__week">{{ time.week }}</div>
        <div class="balloon__open" v-if="time.isOpen">(открытое собрание)</div>
        <div class="balloon__start">c {{ time.timeStart }}</div>
        <div class="balloon__end">c {{ time.timeEnd }}</div>
        <div class="balloon__pause" v-if="time.pause">перерыв</div>
        <div class="balloon__pause" v-else>без перерыва</div>
      </div>
    </div> -->
    <table>
      <thead>
        <tr>
          <th>День недели</th>
          <th>с</th>
          <th>до</th>
          <th>перерыв</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(time, index) in times" :key="index">
          <td>
            <h3>{{ time.week }}</h3>
            <p v-if="time.isOpen">(открытое собрание)</p>
          </td>
          <td>{{ time.timeStart }}</td>
          <td>{{ time.timeEnd }}</td>
          <td>
            <p v-if="time.pause">перерыв</p>
            <p v-else>без перерыва</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["times"],
};
</script>

<style lang="scss">
.balloon {
  h3,
  p {
    margin: 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding-bottom: 0.5rem;
      border-block-end: 1px solid #1c7fe2;
    }
  }
}
</style>