<template>
    <div>
        <slot v-if="visibleSlot">

        </slot>
    </div>
</template>

<script>
    export default {
        props:[{
            visible: Boolean,
            require: true,
        }],
        data(){
            return{
                visibleSlot: Boolean
            }
        },
        mounted(){
            this.visibleSlot = visible
        }
    }
</script>

<style lang="scss">

</style>