<template>
    <div class="zoom-help__body">
        <div class="zoom-andorid" v-show="andr">
            <div class="zoom-andorid__body">
                <h3>1. Устанавливаем приложение Zoom из play market.</h3>
                <img src="http://na-tranzit.org/wp-content/uploads/2017/08/zoom-android-1.png" alt="">
                <h2>Zoom — Android</h2>
                <h3>1. Устанавливаем приложение Zoom из play market.</h3>
                <img src="http://na-tranzit.org/wp-content/uploads/2017/08/zoom-android-1.png" alt="">
                <h3>2. Открываем приложение и жмём «Войти в конференцию».</h3>
                <img src="https://na-tranzit.org/wp-content/uploads/2017/06/Android_Vhod-576x1024.png" alt="">
                <h3>3. Вводим номер комнаты, свое или вымышленное имя, и жмём «Войти в конференцию».</h3>
                <img src="https://na-tranzit.org/wp-content/uploads/2017/06/Android_Komnata_Imia720jpg-576x1024.jpg" alt="">
                <h3>4. Жмём иконку «Звук» и «Вызов с использованием звука устройства», что бы включить звук.</h3>
                <img src="https://na-tranzit.org/wp-content/uploads/2018/08/Android_Zvuk1-576x1024.png" alt="">
                <h4>4.1. Микрофон держим выключенным, включаем только во время высказывания.</h4>
                <h4>4.2. Можно включить или выключить видеокамеру.</h4>
                <h4>4.3. По окончании собрания жмем «Выйти» в верхнем правом углу.</h4>
                <h3>5. Для того что бы написать в чат текстовое сообщение, выбираем раздел «Участники» и в нём кнопку «Чаты».</h3>
                <img src="https://na-tranzit.org/wp-content/uploads/2018/08/Android_Uchastniki1-576x1024.jpg" alt="">
            </div>
        </div>
        <div class="zoom-ios" v-show="ios">
            <div class="zoom-ios__body">
                <h3>1. Устанавливаем приложение из AppStore или по ссылке.</h3>
                <img src="https://na-tranzit.org/wp-content/uploads/2017/08/zoom-ios-1_500x889.jpg" alt="">
                <h3>2. Открываем приложение и жмём «Войти в конференцию».</h3>
                <img src="https://na-tranzit.org/wp-content/uploads/2018/08/IOS_1Ekran-592x1024.jpg" alt="">
                <h3>2. Открываем приложение и жмём «Войти в конференцию».</h3>
                <img src="https://na-tranzit.org/wp-content/uploads/2017/06/Android_Vhod-576x1024.png" alt="">
                <h3>3. Вводим номер комнаты, свое или вымышленное Имя, и жмём «Войти».</h3>
                <img src="https://na-tranzit.org/wp-content/uploads/2018/08/IOS_Komnata_jpeg-576x1024.jpg" alt="">
                <h3>4. Жмём  на иконки микрофона и камеры, что бы включить/выключить звук или камеру.</h3>
                <img src="https://na-tranzit.org/wp-content/uploads/2017/06/IOS_Mic-595x1024.jpg" alt="">
                <h4>4.1. Микрофон держим выключенным, включаем только во время высказывания.</h4>
                <h4>4.2. Можно включить или выключить видеокамеру.</h4>
                <h4>4.3. По окончании собрания жмем надпись «Выйти»  в верхнем правом углу, затем «Выйти из конференции»</h4>
                <h3>5. Для того что бы написать в чат текстовое сообщение, выбираем раздел «Участники» и в нём жмём «Чат».</h3>
                <img src="https://na-tranzit.org/wp-content/uploads/2017/06/IOS_Chat-597x1024.jpg" alt="">
            </div>
        </div>
        <!-- <h2>Zoom — Android</h2> <h2>Zoom — IOS</h2> -->
        <tabs class="link-tabs">
            <tab name="Zoom — Android">
                <div class="zoom-andorid__body">
                    <h3>1. Устанавливаем приложение Zoom из play market.</h3>
                    <img src="http://na-tranzit.org/wp-content/uploads/2017/08/zoom-android-1.png" alt="">
                    <h2>Zoom — Android</h2>
                    <h3>1. Устанавливаем приложение Zoom из play market.</h3>
                    <img src="http://na-tranzit.org/wp-content/uploads/2017/08/zoom-android-1.png" alt="">
                    <h3>2. Открываем приложение и жмём «Войти в конференцию».</h3>
                    <img src="https://na-tranzit.org/wp-content/uploads/2017/06/Android_Vhod-576x1024.png" alt="">
                    <h3>3. Вводим номер комнаты, свое или вымышленное имя, и жмём «Войти в конференцию».</h3>
                    <img src="https://na-tranzit.org/wp-content/uploads/2017/06/Android_Komnata_Imia720jpg-576x1024.jpg" alt="">
                    <h3>4. Жмём иконку «Звук» и «Вызов с использованием звука устройства», что бы включить звук.</h3>
                    <img src="https://na-tranzit.org/wp-content/uploads/2018/08/Android_Zvuk1-576x1024.png" alt="">
                    <h4>4.1. Микрофон держим выключенным, включаем только во время высказывания.</h4>
                    <h4>4.2. Можно включить или выключить видеокамеру.</h4>
                    <h4>4.3. По окончании собрания жмем «Выйти» в верхнем правом углу.</h4>
                    <h3>5. Для того что бы написать в чат текстовое сообщение, выбираем раздел «Участники» и в нём кнопку «Чаты».</h3>
                    <img src="https://na-tranzit.org/wp-content/uploads/2018/08/Android_Uchastniki1-576x1024.jpg" alt="">
                </div>
            </tab>
            <tab name="Zoom — IOS">
                <div class="zoom-ios__body">
                    <h3>1. Устанавливаем приложение из AppStore или по ссылке.</h3>
                    <img src="https://na-tranzit.org/wp-content/uploads/2017/08/zoom-ios-1_500x889.jpg" alt="">
                    <h3>2. Открываем приложение и жмём «Войти в конференцию».</h3>
                    <img src="https://na-tranzit.org/wp-content/uploads/2018/08/IOS_1Ekran-592x1024.jpg" alt="">
                    <h3>2. Открываем приложение и жмём «Войти в конференцию».</h3>
                    <img src="https://na-tranzit.org/wp-content/uploads/2017/06/Android_Vhod-576x1024.png" alt="">
                    <h3>3. Вводим номер комнаты, свое или вымышленное Имя, и жмём «Войти».</h3>
                    <img src="https://na-tranzit.org/wp-content/uploads/2018/08/IOS_Komnata_jpeg-576x1024.jpg" alt="">
                    <h3>4. Жмём  на иконки микрофона и камеры, что бы включить/выключить звук или камеру.</h3>
                    <img src="https://na-tranzit.org/wp-content/uploads/2017/06/IOS_Mic-595x1024.jpg" alt="">
                    <h4>4.1. Микрофон держим выключенным, включаем только во время высказывания.</h4>
                    <h4>4.2. Можно включить или выключить видеокамеру.</h4>
                    <h4>4.3. По окончании собрания жмем надпись «Выйти»  в верхнем правом углу, затем «Выйти из конференции»</h4>
                    <h3>5. Для того что бы написать в чат текстовое сообщение, выбираем раздел «Участники» и в нём жмём «Чат».</h3>
                    <img src="https://na-tranzit.org/wp-content/uploads/2017/06/IOS_Chat-597x1024.jpg" alt="">
                </div>
            </tab>
        </tabs>
    </div>
</template>

<script>
import { isIOS, isAndroid } from 'mobile-device-detect'
    export default {
        data(){
            return{
                ios: isIOS,
                andr: isAndroid,
            }
        },
    }
</script>

<style lang="scss">
    .link-tabs {
        @media (max-width: 600px) {
            display: none;
            a{
                font-size: 1rem;
            }
            .tabs-component-tabs{
                margin: 12px;
                padding: 0;
            }
        }
    }
</style>